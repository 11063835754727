import { isWithinInterval } from 'date-fns'
import MemberGetMemberQ22024 from '~/pages/promotions/configs-promotions/member-get-member-q3-2024.json'
import MemberGetMemberQ2Q32023 from '~/pages/promotions/configs-promotions/member-get-memberQ2Q32023.json'

export default (app, redirect) => {
  if (
    app.$features('DWB-1354-MEMBER-GET-MEMBER-F') &&
    isWithinInterval(
      new Date(), // Now
      {
        start: new Date(
          `${MemberGetMemberQ22024.publishUp} 00:00:00 GMT+07:00`.replace(
            /-/g,
            '/'
          )
        ),
        end: new Date(
          `${MemberGetMemberQ22024.publishDown} 23:59:59 GMT+07:00`.replace(
            /-/g,
            '/'
          )
        )
      }
    )
  ) {
    // correct, do noting
  } else if (
    app.$features('DWB-982-MEMBER-GET-MEMBER-EXTEND-F') &&
    isWithinInterval(
      new Date(), // Now
      {
        start: new Date(
          `${MemberGetMemberQ2Q32023.publishUp} 00:00:00 GMT+07:00`.replace(
            /-/g,
            '/'
          )
        ),
        end: new Date(
          `${MemberGetMemberQ2Q32023.publishDown} 23:59:59 GMT+07:00`.replace(
            /-/g,
            '/'
          )
        )
      }
    )
  ) {
    // correct, do noting
  } else {
    return redirect('/not-found')
  }
}
